import React from "react"
import { Link } from "gatsby"

function PriceCard({ link, name, year, img, price, date }) {
  return (
    <>
      <div className="relative w-full py-2 md:py-0">
        <Link
          to={link}
          className="flex min-h-full flex-col items-center justify-between overflow-hidden rounded-none bg-white py-2 shadow hover:bg-gray-200 hover:shadow-md lg:rounded-xl"
        >
          <div className="mx-auto -mt-2 brightness-100 hover:brightness-90">
            <img src={img} alt={`Фото сажевого фильтра с ${name}`} />
          </div>
          <div className="sr-only absolute top-2 flex flex-col pb-6 text-center text-xl font-extrabold md:pb-0 md:text-2xl">
            <span className="bg-corp-orange p-1 text-white">{name}</span>
            <span className="bg-gray-500/50 p-1 text-white backdrop-blur">
              {year} г
            </span>
          </div>

          <div className="pt-3 text-center">
            <div className="text-lg font-light text-gray-900 md:text-xl">
              Выплата составила{" "}
              <span className="bg-corp-orange p-1 text-xl font-extrabold text-white md:text-2xl">
                {price} ₽
              </span>
            </div>
            <div className="pt-1 text-xs font-light text-gray-900 md:text-sm">
              Дата публикации: {date}
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}

export default PriceCard
