import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CatalogSaj from "../../components/CatalogSaj"

import { sajCars } from "../../components/CatalogSaj/data"

const CatalogSajPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Цены на сажевые фильтры"
          description="Каталог сажевых фильтров, которые мы выкупили у частных клиентов. Цены на них и данные по автомобилю, являющегося донором сажевого фильтра."
        />
      }
      <div className="overflow-hidden bg-gray-100 pt-20 lg:pt-16">
        <CatalogSaj data={sajCars} />
      </div>
    </Layout>
  )
}

export default CatalogSajPage
